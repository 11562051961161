$(document).on("turbolinks:before-cache", function() {
  var table = $('#social-profiles-table').dataTable( { retrieve: true });
  if(table.length > 0) {
    table.fnDestroy();
  }

  $('.available-social-profiles__list').select2('destroy');
  $('.available-social-profiles').hide();
});

$(document).on('ready page:load turbolinks:load', function() {
 
  if($('.reconnect-social-profiles').length > 0) {
    $('.facebook-login')[0].addEventListener('facebook:authorized', function (e) {
      $('#social_profile_reconnect_user_token').val(FB.getAccessToken());
      $('.reconnect-social-profiles').show();
    });
  }

  var isSearchable = $('#social-profiles-table tbody tr').length > 4;

  $('#social-profiles-table').dataTable( {
    destroy: true,
    "bFilter": isSearchable, // determine whether to show search boc
    "aoColumnDefs": [
      { "bSortable": true, "bSearchable": false, "aTargets": [ 0 ] },
      // create a special hidden column for the plain text value to search on
      // otherwise the search logic ends up matching on the url of the link
      { "bSortable": false, "bSearchable": isSearchable, "bVisible": false, "aTargets": [ 1 ] },
    ],
    "aaSorting": [[0, 'asc']], // sort by first column to start
    "bPaginate": false, // no pagination controls
    "bInfo": false, // do not display row counts (e.g. 1 of 2 records found)
    "oLanguage":{
      "sSearch": "", // No label for search box. We add placeholder below
      "sZeroRecords": "No matching profiles"
    },
    "dom": "<'row'<'col-sm-6'f><'col-sm-6'l>>" +
      "<'row'<'col-sm-12'tr>>" +
      "<'row'<'col-sm-5'i><'col-sm-7'p>>"
  });

  if(isSearchable) {
    $('#social-profiles-table_wrapper .dataTables_filter input').addClass("form-control");
    $('#social-profiles-table_wrapper .dataTables_filter input').attr("placeholder", "Filter");
    $('#social-profiles-table_wrapper .dataTables_filter input').focus();
  }

  function findPages(next) {
    var url = next || 'me/accounts?fields=id,name,access_token,instagram_business_account{id,ig_id,name,username,followers_count,profile_picture_url},picture&limit=100';
    FB.api(url, function(response) {
      response.data.forEach(function(page) {
        if(page.instagram_business_account != undefined) {
          if(!window.tackAlreadyConnectedSocialProfiles.includes(page.instagram_business_account.id)) {
            addPageOption(page);
          }
        } else {
          addPlaceholderPageOption(page);
        }
      });

      if(response.paging.next != undefined) {
        findPages(response.paging.next);
      }
    });
    return false;
  }

  function addPageOption(page) {
    if($('.available-social-profiles__list option[value="' + page.id + '"]').length > 0) {
      return;
    }
    
    var option = $('<option value="' + page.id + '">' + page.instagram_business_account.username + '</option>');
    option.data('page_uid', page.id);
    option.data('uid', page.instagram_business_account.id);
    option.data('username', page.instagram_business_account.username);
    option.data('name', page.instagram_business_account.name);
    option.data('follower_count', page.instagram_business_account.followers_count);
    option.data('profile_picture', page.instagram_business_account.profile_picture_url);
    option.data('ig_uid', page.instagram_business_account.ig_id);
    $('.available-social-profiles__list').append(option);
    $('.available-social-profiles__list').trigger('change');
  }

  function addPlaceholderPageOption(page) {
    if($('.available-social-profiles__list option[value="' + page.id + '"]').length > 0) {
      return;
    }
    
    var option = $('<option value="' + page.id + '">' + page.name + ' (not linked) </option>');
    option.data('page_uid', page.id);
    option.data('uid', page.id);
    option.data('username', page.name);
    option.data('name', page.name);
    option.data('follower_count', 0);
    option.data('profile_picture', page.picture.data.url);
    option.data('ig_uid', '');
    //Only for Meta permission review
    //$('.available-social-profiles__list').append(option);
    //$('.available-social-profiles__list').trigger('change');
  }

  if($('.available-social-profiles__list').length > 0 ) {
    $('.facebook-login')[0].addEventListener('facebook:authorized', function (e) {
      if($('.available-social-profiles').length > 0) {
        findPages(null);        
        $('#social_profile_user_token').val(FB.getAccessToken());
        $('.available-social-profiles').show();
      }
    }, false);
  }
  
  $('.available-social-profiles__list').select2({
    theme: "bootstrap",
    placeholder: 'loading...',
    sorter: function(data) {
      return data.sort(function (a, b) {
          a = a.text.toLowerCase();
          b = b.text.toLowerCase();
          if (a > b) {
            return 1;
          } else if (a < b) {
            return -1;
          }
          return 0;
      });
    },
    templateSelection: function(data) {
      if($(data.element).data('profile_picture') == undefined) {
        return data.text;
      }
      var img = $('<img>').attr('src',$(data.element).data('profile_picture')).addClass('available-social-profiles__img');
      return $('<div>').append(img).append(' ' + data.text);
    },
    templateResult: function(data) {
      var img = $('<img>').attr('src',$(data.element).data('profile_picture')).addClass('available-social-profiles__img');
      return $('<div>').append(img).append(' ' + data.text);
    }
  }); 
  
  $('.available-social-profiles__list').on('change.select2', function (e) {
    var select2data = $('.available-social-profiles__list').select2('data')
    updateForm($(select2data[0].element));
  });

  function updateForm(selected) {
    $('#social_profile_page_uid').val(selected.data('page_uid'));
    $('#social_profile_uid').val(selected.data('uid'));
    $('#social_profile_username').val(selected.data('username'));
    $('#social_profile_name').val(selected.data('name'));
    $('#social_profile_follower_count').val(selected.data('follower_count'));
    $('#social_profile_profile_picture').val(selected.data('profile_picture'));
    $('#social_profile_ig_uid').val(selected.data('ig_uid'));
  }

});
