import instantsearch from 'instantsearch.js';
import {searchBox} from 'instantsearch.js/es/widgets';
import {currentRefinedValues} from 'instantsearch.js/es/widgets';
import {hitsPerPageSelector} from 'instantsearch.js/es/widgets';
import {hits} from 'instantsearch.js/es/widgets';
import {stats} from 'instantsearch.js/es/widgets';
import {pagination} from 'instantsearch.js/es/widgets';
import {refinementList} from 'instantsearch.js/es/widgets';
import {connectRange} from 'instantsearch.js/es/connectors';
import MediaAssetAction from './media_asset_action';

$(document).on('ready page:load turbolinks:load', function() {

  if($('#algolia-app').length == 0) { return; }

  jQuery.timeago.settings.strings = {
    prefixAgo: null,
    prefixFromNow: null,
    suffixAgo: "",
    suffixFromNow: "",
    seconds: "1m",
    minute: "1m",
    minutes: "%dm",
    hour: "1h",
    hours: "%dh",
    day: "1d",
    days: "%dd",
    month: "1mo",
    months: "%dmo",
    year: "1yr",
    years: "%dyr",
    wordSeparator: " ",
    numbers: []
  };

  app({
    appId: $('#algolia-app').data('app-id'),
    apiKey: $('#algolia-app').data('key'),
    indexName: 'MediaAsset',
  });

function app(opts) {
  var search = instantsearch({
    appId: opts.appId,
    apiKey: opts.apiKey,
    indexName: opts.indexName,
    routing: {
      stateMapping: {
        stateToRoute(uiState) {
          return {
            turbolinks: true,
            ... uiState,
          };
        },
        routeToState({turbolinks, ...routeState}) {
          return routeState
        }
      }
    },
    searchParameters: { 
      advancedSyntax: false, 
      hitsPerPage: 10, 
      facets: ['status','_tags','source_data.uid'],
      facetsRefinements: {
        'status': ['active']
      }
    },
  });
  window.algoliasearch = search;

  var onRenderHandler = function() {
    new Readmore('.caption', {
      speed: 5,
      collapsedHeight: 36,
      moreLink: '<a style="font-size:10px;margin-bottom:8px;margin-top:-8px;" href="#">[&hellip;]</a>',
      lessLink: '<a></a>'
    });
    new Readmore('#hits .notes-wrapper .notes-body', {
      speed: 5,
      collapsedHeight: 58,
      moreLink: '<a style="font-size:10px;margin-bottom:8px;margin-top:-8px;" href="#">[&hellip;]</a>',
      lessLink: '<a></a>'
    });
    $('[data-toggle="popover"]').popover();
    var action = new MediaAssetAction();
    action.bindStatus($('.media-asset__action-status-details a'));
    action.bindRetire($('.media-asset__action-retire'));
    action.displayTikTok($('.post-attribution-tiktok-username'));
  };
  search.on('render', onRenderHandler);

  search.templatesConfig.helpers.formatTime = function(text, render) {
    var time = render(text);
    var date = new Date(parseInt(time)*1000)
    return date.toLocaleString()
  };

  var weeksOnly = function(value, distanceMillis) {
    var weeksOnly = Math.abs(distanceMillis) / 1000 / 60 / 60 / 24 / 7;
    return Math.round(weeksOnly) + "w";
  }
  $.timeago.settings.strings.month  = weeksOnly;
  $.timeago.settings.strings.months = weeksOnly;
  $.timeago.settings.strings.year   = weeksOnly;
  $.timeago.settings.strings.years  = weeksOnly;

  search.templatesConfig.helpers.formatShortTimeAgo = function(text, render) {
    var time = render(text);
    var date = new Date(parseInt(time)*1000)
    return $.timeago(date);
  };

  search.templatesConfig.helpers.formatMedia = function(text, render) {
    return text;
  };


  search.templatesConfig.helpers.formatName = function(text, render) {
    var name = render(text);
    var formattedName = name;
    if(name.startsWith('-')) {
      formattedName = '(NOT ' + name.substring(1, name.length) + ')';
    }

    if(!isNaN(name)) {
      var date = new Date(parseFloat(name)*1000)
      if(date instanceof Date && !isNaN(date)) {
          formattedName = date.toLocaleDateString();
      }
    }

    return formattedName;
  };

  search.templatesConfig.helpers.formatExclusion = function(text, render) {
    return ' <a class="ais-refinement-list--exclude" href="#" data-tag="' + text + '">x</a> '
  };

  $(document).on('click','.ais-refinement-list--label a', function(e){
    search.helper.addTag('-' + $(e.target)[0].dataset.tag);
    search.helper.search();
  });

  $(document).on('media-asset-changed','.search-page', function(e){
    search.client.clearCache();
  });

  search.addWidget(
    currentRefinedValues({
      container: '#current-refined-values',
      clearAll: 'after',
      cssClasses: { clearAll: 'btn btn-default btn-xs' },
      onlyListedAttributes: true,
      attributes: [
        {name: 'media_type'}, 
        {name: '_tags'}, 
        {name: 'location_name'}, 
        {name: 'source_data.tags'}, 
        {name: 'source_data.user.username'}, 
        {name: 'social_profile_username'}, 
        {name: 'created_at_to_i'},
        {name: 'source_data.uid', label: 'ID'},
        {name: 'labels.name' }
      ],
      templates: {
        clearAll: 'Remove all filters',
        item: '' +
        '{{#label}}' +
          '{{label}}' +
          '{{^operator}}:{{/operator}}' +
          ' ' +
        '{{/label}}' +
        '{{#operator}}{{{displayOperator}}} {{/operator}}' +
        '{{#exclude}}NOT {{/exclude}}' +
        '{{#helpers.formatName}}{{name}}{{/helpers.formatName}}'
      }
    })
  );

  search.addWidget(
    hitsPerPageSelector({
      container: '#hits-per-page-selector',
      items: [
        {value: 10, label: '10 per page'},
        {value: 50, label: '50 per page'},
        {value: 100, label: '100 per page'}
      ]
    })
  );

  $('#search-input').html('');
  search.addWidget(
    searchBox({
      container: '#search-input',
      placeholder: 'Search for media',
      autofocus: true,
      reset: false
    })
  );

  search.addWidget(
    hits({
      container: '#hits',
      templates: {
        item: getTemplate('hit'),
        empty: getTemplate('no-results')
      },
      transformData: {
        item: function(item) {
          var media_tag = '';
          var media_path = '';
          if(item.media_type == 'image') {
            media_tag = getTemplate('image-media').replace(/FILE/gi, item.media_file_path).replace(/ALTTEXT/gi, (item.labels && item.labels.length > 0) ? 'Image may contain: ' + item.labels.map(i => i.name).join(', ') : 'Image');
            media_path = getTemplate('image-media-file').replace(/FILE/gi, item.media_file_path).trim();
          } else {
            media_tag = getTemplate('video-media').replace(/FILE/gi, item.media_file_path);
            media_path = getTemplate('video-media-file').replace(/FILE/gi, item.media_file_path).trim();
          }
          item.media_tag = media_tag;
          item.media_path = media_path;
          item.is_active = (item.status == 'active');
          return item;
        }
      }
    })
  );

  /*search.addWidget(
    instantsearch.widgets.numericRefinementList({
    container: '#date-added',
    attributeName: 'created_at_to_i',
    options: [
      {name: 'Any time'},
      {start: Date.now()/1000 - 7*24*60*60, name: 'last 7 days'},
      {start: Date.now()/1000 - 30*24*60*60,  name: 'last 30 days'},
      {start: Date.now()/1000 - 90*24*60*60, name: 'last 90 days'},
      {end: Date.now()/1000 - 90*24*60*60, name: 'more than 90 days ago'}
    ],
    templates: {
      header: getHeader('Added','calendar')
    }
    })
  );*/

  function getStartDate(searchInstance) {
    var start_date = undefined; // after
    if(searchInstance.helper.state.numericRefinements.created_at_to_i != undefined && 
      searchInstance.helper.state.numericRefinements.created_at_to_i['>='] != undefined) {
        start_date = searchInstance.helper.state.numericRefinements.created_at_to_i['>='][0];
    }
    return start_date;
  }

  function getEndDate(searchInstance) {
    var end_date = undefined; // before
    if(searchInstance.helper.state.numericRefinements.created_at_to_i != undefined && 
      searchInstance.helper.state.numericRefinements.created_at_to_i['<='] != undefined) {
        end_date = searchInstance.helper.state.numericRefinements.created_at_to_i['<='][0];        
    }
    return end_date;
  }

  var makeDateRangeWidget = connectRange(
    function(options, isFirstRendering) {
      if($('#algolia-app').length == 0) { return; }

      if(isFirstRendering) {
        var refine = options.refine;
        var instantSearchInstance = options.instantSearchInstance;
        var fpBefore = $('#date-added-before.flatpickr').flatpickr({
          wrap: true,
          dateFormat: 'M j, Y',
          onChange: function(selectedDates, dateStr, instance) {
            if(selectedDates.length == 0) {
              refine([getStartDate(instantSearchInstance), undefined]);

            } else {
              end_date = selectedDates[0].getTime()/1000 + 24*60*60 - 1;
              refine([getStartDate(instantSearchInstance), end_date]);            
            }
          },
        });
        var fpAfter = $('#date-added-after.flatpickr').flatpickr({
          wrap: true,
          dateFormat: 'M j, Y',
          onChange: function(selectedDates, dateStr, instance) {
            if(selectedDates.length == 0) {
              refine([undefined, getEndDate(instantSearchInstance)]);

            } else {
              refine([selectedDates[0].getTime()/1000, getEndDate(instantSearchInstance)]);            
            }
          },
        });
      }
      else {
        if (options.instantSearchInstance.helper.lastResults != undefined && options.instantSearchInstance.helper.lastResults.hits.length > 0) {
          $('.flatpickr').show();
        }
        else {
          $('.flatpickr').hide();
        }  
      }

      var fpBefore = document.querySelector('#date-added-before.flatpickr')._flatpickr;
      fpBefore.set("maxDate", new Date().setHours(23,59,59,999));

      var fpAfter = document.querySelector('#date-added-after.flatpickr')._flatpickr;

      var end_date = getEndDate(options.instantSearchInstance);
      if (end_date != undefined) {
        fpBefore.setDate(new Date(end_date*1000).setHours(23,59,59,999), false);
        fpAfter.set("maxDate", new Date(end_date*1000));
      } else { 
        fpBefore.setDate(undefined, false); 
        fpAfter.set("maxDate", undefined);
      }

      var start_date = getStartDate(options.instantSearchInstance);
      if (start_date != undefined) {
        fpAfter.setDate(new Date(start_date*1000), false);
        fpBefore.set("minDate", new Date(start_date*1000));
      } else { 
        fpAfter.setDate(undefined, false); 
        fpBefore.set("minDate", undefined);
      }
    }
  );
  
  var dateRangeWidget = makeDateRangeWidget({
    attributeName: 'created_at_to_i'
  });
  
  search.addWidget(dateRangeWidget);

  search.addWidget(
    stats({
      container: '#stats',
      templates: {
        header: '',
        body: '{{#hasNoResults}}No results{{/hasNoResults}}'+
               '{{#hasOneResult}}1 result{{/hasOneResult}}'+
               '{{#hasManyResults}}{{#helpers.formatNumber}}{{nbHits}}{{/helpers.formatNumber}} results{{/hasManyResults}}',
        footer: ''
      }
    })
  );

  search.addWidget(
    pagination({
      container: '#pagination',
      scrollTo: '#search-input'
    })
  );

  search.addWidget(
    refinementList({
      container: '#tags',
      attributeName: '_tags',
      sortBy: ['isRefined', 'count:desc', 'name:asc'],
      searchForFacetValues: {
        placeholder: 'Search for tags',
        templates: {
          noResults: '<div class="sffv_no-results">No matching tags.</div>',
        },
      },
      limit: 10,
      operator: 'or',
      showMore: {
        limit: 25
      },
      templates: {
        header: getHeader('Tags','tags'),
        item: '<label class="{{cssClasses.label}}">' +
  '<input type="checkbox" class="{{cssClasses.checkbox}}" value="{{value}}" {{#isRefined}}checked{{/isRefined}} />{{value}} {{#helpers.formatExclusion}}{{value}}{{/helpers.formatExclusion}}' +
  '<span class="{{cssClasses.count}}">{{#helpers.formatNumber}}{{count}}{{/helpers.formatNumber}}</span></label>'
      }
    })
  );
  
  search.addWidget(
    refinementList({
      container: '#uid',
      attributeName: 'source_data.uid',
      limit: 10,
      templates: {
        header: 'ID'
      }
    })
  );

  search.addWidget(
    refinementList({
      container: '#labels',
      attributeName: 'labels.name',
      sortBy: ['isRefined', 'count:desc', 'name:asc'],
      searchForFacetValues: {
        placeholder: 'Search for labels',
        templates: {
          noResults: '<div class="sffv_no-results">No matching labels.</div>',
        },
      },
      limit: 10,
      operator: 'or',
      showMore: {
        limit: 25
      },
      templates: {
        header: getHeader('Automatic Labels','superpowers')
      }
    })
  );

  search.addWidget(
    refinementList({
      container: '#location',
      attributeName: 'location_name',
      sortBy: ['isRefined', 'count:desc', 'name:asc'],
      searchForFacetValues: {
        placeholder: 'Search for locations',
        templates: {
          noResults: '<div class="sffv_no-results">No matching locations.</div>',
        },
      },
      limit: 10,
      operator: 'or',
      showMore: {
        limit: 25
      },
      templates: {
        header: getHeader('Location','map-marker')
      }
    })
  );

  search.addWidget(
    refinementList({
      container: '#hashtags',
      attributeName: 'source_data.tags',
      sortBy: ['isRefined', 'count:desc', 'name:asc'],
      searchForFacetValues: {
        placeholder: 'Search for hashtag',
        templates: {
          noResults: '<div class="sffv_no-results">No matching hashtags.</div>',
        },
      },
      limit: 10,
      operator: 'or',
      showMore: {
        limit: 25
      },
      templates: {
        header: getHeader('Hashtags','hashtag')
      }
    })
  );

  search.addWidget(
    refinementList({
      container: '#username',
      attributeName: 'source_data.user.username',
      sortBy: ['isRefined', 'count:desc', 'name:asc'],
      searchForFacetValues: {
        placeholder: 'Search for username',
        templates: {
          noResults: '<div class="sffv_no-results">No matching usernames.</div>',
        },
      },
      limit: 10,
      operator: 'or',
      showMore: {
        limit: 25
      },
      templates: {
        header: getHeader('Username', 'user')
      }
    })
  );

  if($('#profile').length > 0) {
  search.addWidget(
    refinementList({
      container: '#profile',
      attributeName: 'social_profile_username',
      sortBy: ['isRefined', 'count:desc', 'name:asc'],
      searchForFacetValues: {
        placeholder: 'Search for profile',
        templates: {
          noResults: '<div class="sffv_no-results">No matching profiles.</div>',
        },
      },
      limit: 10,
      operator: 'or',
      showMore: {
        limit: 25
      },
      templates: {
        header: getHeader('Profile', 'instagram')
      }
    })
  );
  }

  search.addWidget(
    refinementList({
      container: '#type',
      attributeName: 'media_type',
      sortBy: ['name:asc'],
      limit: 2,
      operator: 'or',
      templates: {
        header: getHeader('Type','file-o')
      }
    })
  );

  search.addWidget(
    refinementList({
      container: '#status',
      attributeName: 'status',
      collapsible: {collapsed: true},
      templates: {
        header: getHeader('Status','info-circle')
      }
    })
  );

  search.start();
}

function getTemplate(templateName) {
  var templateHTML = document.querySelector('#' + templateName + '-template');
  if(!templateHTML) {
    return '';
  }

  return templateHTML.innerHTML;
}

function getHeader(title, icon) {
  return '<h5><i class="fa fa-' + icon + '"></i> ' + title + '</h5>';
}

});