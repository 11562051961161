/* eslint no-console:0 */
import Rails from '@rails/ujs'
Rails.start();

import Turbolinks from 'turbolinks'
Turbolinks.start();

import 'jquery'
import 'bootstrap-sass'
import 'timeago'
import 'flatpickr'
import 'datatables.net'
import 'select2'
import 'flickity'
import 'flickity-imagesloaded'

import 'url-search-params-polyfill';
import '../src/javascript/custom_event_polyfill'

import '../../../vendor/assets/javascripts/segment'
import '../../../vendor/assets/javascripts/jquery.tokenize'
import '../../../vendor/assets/javascripts/jquery.storageapi.min'

import '../../../vendor/assets/javascripts/angle/modules/bootstrap-start'
import '../../../vendor/assets/javascripts/angle/modules/calendar'
import '../../../vendor/assets/javascripts/angle/modules/clear-storage'
import '../../../vendor/assets/javascripts/angle/modules/constants'
import '../../../vendor/assets/javascripts/angle/modules/load-css'
import '../../../vendor/assets/javascripts/angle/modules/localize'
import '../../../vendor/assets/javascripts/angle/modules/panel-tools'
import '../../../vendor/assets/javascripts/angle/modules/utils'
import '../../../vendor/assets/javascripts/angle/modules/toggle-state'
import '../../../vendor/assets/javascripts/angle/app.init'

import '../../../vendor/assets/javascripts/readmore-vanilla.js'

import '../src/javascript/segment'

import '../src/javascript/bookmark_folders'
import '../src/javascript/bookmarks'
import '../src/javascript/facebook'
import '../src/javascript/media_assets'
import '../src/javascript/media_asset_action'
import '../src/javascript/inbox_items'
import '../src/javascript/social_profiles'
import '../src/javascript/tackboards'
import '../src/javascript/tags'
import '../src/javascript/users'
import '../src/javascript/orgs'
import '../src/javascript/reports'
import '../src/javascript/creators'