$(document).on("turbolinks:before-cache", function() {
  $( "#acts_as_taggable_on_tag_target_tag_name" ).select2('destroy');
});

$(document).on('ready page:load turbolinks:load', function() {
	$( "#acts_as_taggable_on_tag_target_tag_name" ).select2({
    theme: "bootstrap",
		placeholder: ' ',
		maximumInputLength: 20,
		minimumResultsForSearch: 10,
		templateResult: formatSelect,
		templateSelection: formatSelect
	});

	function formatSelect (selectState) {
	  if (!selectState.id) { return selectState.text; }
		var content = $(selectState.element).data('content')
		if(content) {
			return $('<span>').append(content);
		}
	  return selectState.text;
	};

	$('#tag-merge-button').prop('disabled', true);
	$("#acts_as_taggable_on_tag_target_tag_name").change(function () {
			if($(this).find('option:selected').text() == '') {
				$('#tag-merge-button').prop('disabled', true);
			} else {
			  $('#tag-merge-button').data('confirm',  "Merge everything tagged '" + $(this).data('tag-name') + "' into '" + $(this).find('option:selected').text() + "'?")
				$('#tag-merge-button').prop('disabled', false);
			}
	});
});
