import swal from 'sweetalert2';
import Rails from '@rails/ujs';
import Hogan from 'hogan.js';

function capitalize(str1){
  return str1.charAt(0).toUpperCase() + str1.slice(1);
}

const reasons = { 
  'quality': 'Quality (e.g., Poor Image, Brand Standards)', 
  'not_needed': 'Not Needed (e.g., Duplicate, Carousel Extra)', 
  'outdated': 'Outdated (e.g., Old, No Longer Relevant)', 
  'disputed': 'Disputed (e.g., Complaint, Rights Unclear)', 
  'other': 'Other' 
};

class MediaAssetAction {
  bindStatus(element) {
    element.on('click', function(e){ 
      e.preventDefault();
      var template = Hogan.compile($('#retire-status-template').text());

      swal({
        title: 'Status Details',
        showCloseButton: true,
        onOpen: () => {
          swal.showLoading();
          return Rails.ajax({
            type: "GET", 
            url: e.originalEvent.target.closest('a').href + '.json',
            success: function(response) { 
              swal.hideLoading();
              var history = response.media_asset.status_history;
              if(history == undefined || history.entries.length == 0) {
                swal.getContent().textContent = 'No status details available';
              } else {
                var entry = history.entries[history.entries.length - 1];
                entry.date = new Date(entry.timestamp).toLocaleString();
                entry.status = capitalize(entry.status);
                entry.reason = reasons[entry.reason];
                swal.getContent().innerHTML = template.render(entry);
              }
            },
            error: function(r) { 
              swal.hideLoading();
              swal.showValidationError("Unable to load status info.")
            }
          });
        }
      });
    });
  }

  bindRetire(element) {
    element.on('click', function(e){ 
      e.preventDefault();
      var template = Hogan.compile($('#retire-template').text());
      var output = template.render({});
      
      var imageUrl = $(e.originalEvent.target).closest('.media-asset-container').find('img').attr('src');

      swal.mixin({
        title: 'Retire media',
        showCloseButton: true,
        progressSteps: ['Reason', 'Notes', 'Confirm'],
        focusConfirm: false,
        width: '40rem',
        imageUrl: imageUrl,
        animation: false
      }).queue([{
        confirmButtonText: 'Next &rarr;',
        input: 'radio',
        inputOptions: reasons,
        inputPlaceholder: 'Reason',
        inputValidator: (value) => {
          return !value && 'You must choose a reason';
        },
        preConfirm: (result1) => {
          return new Promise(function (resolve) {
            swal.insertQueueStep({
              title: reasons[result1],
              confirmButtonText: 'Next &rarr;',
              input: 'textarea',
              inputPlaceholder: 'Notes on why this media is being retired',
              inputValidator: (value) => {
                return (result1 == 'other') && !value && 'You must leave notes when the reason is "Other"';
              },
              preConfirm: (result2) => {
                return new Promise(function (resolve) {
                  swal.insertQueueStep({
                    title: reasons[result1],
                    text: result2,
                    confirmButtonText: 'Retire media'
                  });
                  resolve([result1, result2]);
                  return
                });
              }
            });
            resolve();
          });
        }
      }]).then((result) => {
        if(result.dismiss != undefined) {
          return;
        }
        let formData = new FormData();
        formData.append('media_asset[status]', 'retired')
        formData.append('media_asset[status_history_data]', JSON.stringify({ reason: result.value[1][0], notes: result.value[1][1] }));
        Rails.ajax({
          type: "PATCH", 
          data: formData,
          url: e.originalEvent.target.href
        });
      });

      return false;
    });
  }

  bindTagInputs(selector, overlaySelector) {
    $(selector).each(function(index, element){
      $(element).tokenize({
        newElements: $(element).data('newelements'),
        datas: $(element).data('tagsearch') || window.location.href.split('/').splice(0,5).join('/') + '/tags.json',
        searchParam: 'q',
        textField: 'name',
        valueField: 'name',
        delimiter: [',',' '],
        debounce: 200,
        autosize: true,
        placeholder:  $(element).data('placeholder'),
        nbDropdownElements: 100,
        searchMaxLength: 25,
        onClear: function(e) {
          if(e.select.data('autofocus')) {
            e.searchInput.focus();
          }
        },
        onAddToken: function(value, text, e) {
          if(e.select.data('autosubmit')) {
            Rails.fire(e.select.parents('form')[0], 'submit');
          }
        },
        onRemoveToken: function(value, e) {
          if(e.select.data('autosubmit')) {
            Rails.fire(e.select.parents('form')[0], 'submit');
          }
        },
        onDuplicateToken: function(value, text, e) {
          tag = $('li.Token[data-value="' + value + '"]', e.tokensContainer);
          tag.hide();
          tag.fadeIn();
        }
      })
  
      if(overlaySelector) {
        $(overlaySelector).on('click', function (event) {
          $(this).next().next().find('input').focus();
          $(this).next().next().tokenize().search();
        });
      }
    });
  }  

  static setExpandableTextareaRows(element) {
    var minRows = element.getAttribute('data-min-rows')|0, rows;
    element.rows = minRows;
    rows = Math.ceil((element.scrollHeight - element.baseScrollHeight) / 17);
    element.rows = minRows + rows;
  }
  
  bindExpandableTextarea(element){
    $(element)
      .one('focus', function(){
          var savedValue = this.value;
          this.value = '';
          this.baseScrollHeight = this.scrollHeight;
          this.value = savedValue;
          MediaAssetAction.setExpandableTextareaRows(this);
      })
      .on('input', function(){
        MediaAssetAction.setExpandableTextareaRows(this);
      });
  
      $(element).focus();
  }

  displayTikTok(elements) {
    elements.each(function(index, element){ 
      var username = $(element).data('username');
      var tiktok_username = window.usernameCacheTikTok[username]
      if(tiktok_username == undefined) {
        Rails.ajax({
          type: "GET", 
          url: $(element).data('src') + '.json',
          success: function(response) { 
            tiktok_username = response.tiktok_username;
            if(tiktok_username) {
              element.innerHTML = '<a href="https://tiktok.com/@' + tiktok_username + '" target="_blank">' + tiktok_username + '</a>';
              $(element).parent().removeClass('hidden');
            }
            window.usernameCacheTikTok[username] = response.tiktok_username || '@';
          },
          error: function(r) { 
          }
        });
      } else {
        if(tiktok_username != null && tiktok_username != '@') {
          element.innerHTML = '<a href="https://tiktok.com/@' + tiktok_username + '" target="_blank">' + tiktok_username + '</a>';
          $(element).parent().removeClass('hidden');
        }
      }
    });
  }
}

export default MediaAssetAction;

window.MediaAssetAction = MediaAssetAction;
window.usernameCacheTikTok = {};