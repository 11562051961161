$(document).on("turbolinks:before-cache", function() {
  $('[data-turbolinks-no-cache]').remove();
  $('.facebook-login').hide();
});

$(document).on('ready page:load turbolinks:load', function() {
  $('.fb-reauth-button').off('click').on('click', function() {
    var requiredScope = $('div.fb-login-button')[0].getAttribute('scope').split(',')
    FB.getLoginStatus(function (r2) {
      FB.login(function(response){
        statusChangeCallback(response);
      }, { auth_type: 'reauthorize', scope: requiredScope });
    });
  });
  
  if (typeof FB !== "undefined" && FB !== null) {
    FB.XFBML.parse();
    checkLoginState();
  }
  else {
    var appId = $('.facebook-data').data('appid');
    if(appId != undefined) {
      window.fbAsyncInit = function() {
        FB.init({
          appId      : appId,
          xfbml      : true,
          version    : 'v10.0'
        });

        checkLoginState();
      };

      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v10.0&appId=" + appId;
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    }
  }
  
  // This is called with the results from from FB.getLoginStatus().
  function statusChangeCallback(response) {
    if($('div.fb-login-button').length == 0) {
      return;
    }
    var requiredScope = $('div.fb-login-button')[0].getAttribute('scope').split(',')
    // The response object is returned with a status field that lets the
    // app know the current login status of the person.
    // Full docs on the response object can be found in the documentation
    // for FB.getLoginStatus().
    // Only prompt for reauth if we get the data and its within 7 days
    var skip_reauth = (typeof(response.authResponse.data_access_expiration_time) == 'undefined') || (new Date(response.authResponse.data_access_expiration_time*1000) - new Date()) > (30*24*3600*1000);
    if (response.status === 'connected' && skip_reauth) {
      // Logged into your app and Facebook.
      FB.api('me/permissions',function(response) {
        var grantedScope = response.data.map(function(p) {
          if(p.status == 'granted') {
            return p.permission;
          }
        }).filter(function(p) { return p != undefined; })
        hasRequiredScope = requiredScope.reduce(function(r, s) { return r && grantedScope.includes(s) }, true);
        onConnected(hasRequiredScope);
      });
    } else if (response.status === 'connected' && !skip_reauth) {
      $('.facebook-login .facebook-login__status').html('<div>Please reauthorize to Facebook to continue.</div>');
      $('div.fb-login-button').hide();
      $('.fb-reauth-button').show();
    } else if (response.status === 'not_authorized') {
      // The person is logged into Facebook, but not our app.
      $('.facebook-login .facebook-login__status').html('<div>Please connect to Facebook to continue.</div>');
      $('.facebook-login .facebook-login__status').show();
      $('.fb-reauth-button').hide();
    } else {
      // The person is not logged into Facebook, so we're not sure if
      // they are logged into the app or not.
      $('.facebook-login .facebook-login__status').html('<div>Please connect to Facebook to continue.</div>');
      $('.facebook-login .facebook-login__status').show();
      $('.fb-reauth-button').hide();
    }
  }

  // This function is called when someone finishes with the Login Button.
  window.checkLoginState = function checkLoginState() {
    FB.getLoginStatus(function(response) {
      statusChangeCallback(response);
    });
  }

  function onConnected(hasRequiredScope) {
    if(hasRequiredScope) {
      $('.facebook-login').hide();
      $('.facebook-login')[0].dispatchEvent(new Event('facebook:authorized', { bubbles: true }));
    }
    else {
      $('.facebook-login .facebook-login__status').html('<div>You do not have the required permissions.</div>');
      $('.facebook-login .facebook-login__status').show();
      $('.fb-reauth-button').hide();
    }
  }
});