import Rails from '@rails/ujs'

$(document).on('ready page:load turbolinks:load bookmarks:reload', function() {
  
  $('#bookmark-new').on('show.bs.collapse hide.bs.collapse', function () {
    $('#bookmark-new-link').toggleClass('hidden');
  });

  $('#bookmark-new').on('shown.bs.collapse', function () {
    $('#bookmark-new input#bookmark_link').focus();
  });  

  function decode_entities(input) {
    if (typeof(input) == "string") {
      input = input.replace(/&gt;/ig, ">");
      input = input.replace(/&lt;/ig, "<");
      input = input.replace(/&#39;/g, "'");
      input = input.replace(/&quot;/ig, '"');
      input = input.replace(/&amp;/ig, '&');
    }

    return input;
  }

  function linkChanged() {
    $('#bookmark-new input#bookmark_name').val('');
    $('#bookmark-new input#bookmark_name').attr("readonly", "readonly");
    $('#bookmark-new input[type=submit]').attr("disabled", "disabled");
    $('#bookmark-new .bookmark_link').append('<div id="bookmark-processing" style="position: relative"><i style="position:absolute;top:-23px;left:2px" class="fa fa-spinner fa-spin"></i>');
    var link = $('#bookmark-new input#bookmark_link').val();

    /*var xhr = new XMLHttpRequest();
    xhr.open("GET", link, true);
    xhr.onreadystatechange = function() { 
      if (xhr.readyState == 4) {
        $("#bookmark-processing").remove();
        if(xhr.status == 200 && !xhr.responseURL.startsWith('https://www.instagram.com/accounts/login/')) {
          var rawTitle = (/<title>([^]*) • ([^]*)<\/title>/m).exec(xhr.responseText.substr(0,500));
          if(rawTitle != null) {
            var title = rawTitle[1].toString();
            $('#bookmark-new input#bookmark_name').val(decode_entities(title));
            $('#bookmark-new input#bookmark_name').removeAttr("readonly");
            $('#bookmark-new input[type=submit]').removeAttr("disabled");
          }
        }
      }
    }
    xhr.send();*/

    $("#bookmark-processing").remove();
    if(link.includes('instagram.com/explore') || (link.includes('instagram.com/') && link.includes('tagged'))) {
      $('#bookmark-new input#bookmark_name').removeAttr("readonly");
      $('#bookmark-new input[type=submit]').removeAttr("disabled");
    }
  }

  $('#bookmark-new input#bookmark_link').on('input', $.Utils.debounce(function() { linkChanged() }, 300));  

  $('.bookmark').off("dragstart").on("dragstart", onDragStart);

  function onDragStart(event) {
    event.stopPropagation();
    event.originalEvent.dataTransfer.effectAllowed = 'move';
    event.originalEvent.dataTransfer.setData("bookmark-url", $(event.target).data('bookmark-url'))
    event.originalEvent.dataTransfer.setData("bookmark-id", $(event.target).data('bookmark-id'))
    event.originalEvent.dataTransfer.setData("bookmark-name", $(event.target).data('bookmark-name'))
    event.originalEvent.dataTransfer.setData("bookmark-link", $(event.target).data('bookmark-link'))
    event.originalEvent.dataTransfer.setData("bookmark-folder-id", $(event.target).data('bookmark-folder-id'))
  }

  $('.bookmark-folder').off("dragenter dragover dragleave drop")
                       .on("dragenter", onDragEnter)
                       .on("dragover", onDragOver)
                       .on("dragleave", onDragLeave)
                       .on("drop", onDrop);
                       
  function getFolder(event) {
    var folder = null;
    if($(event.toElement).hasClass('bookmark-folder')) {
      folder = $(event.toElement)
    }
    else {
      folder = $($(event.toElement).parents('.bookmark-folder')[0])
    }
    return folder;
  }

  function onDragEnter(event) {
    event.preventDefault();
    if(!getFolder(event).hasClass('dragover')) {
      getFolder(event).addClass('dragover');
    }
  }

  function onDragOver(event) {
    event.preventDefault();
  }

  function onDragLeave(event) {
    event.preventDefault();
    if(getFolder(event).hasClass('dragover')) {
      getFolder(event).removeClass('dragover')
    }
  }

  function onDrop(event) {
    event.preventDefault();
    getFolder(event).removeClass('dragover')

    var sourceFolderId = event.originalEvent.dataTransfer.getData('bookmark-folder-id');
    var targetFolderId = getFolder(event).data('bookmark-folder-id');

    if(sourceFolderId == targetFolderId) {
      return;
    }

    var url = event.originalEvent.dataTransfer.getData('bookmark-url');
    var link = event.originalEvent.dataTransfer.getData('bookmark-link');
    var name = event.originalEvent.dataTransfer.getData('bookmark-name');
    moveBookmark(targetFolderId, url, link, name);
  }

  function moveBookmark(targetFolderId, url, link, name) {
    $('#bookmark-update-form-wrapper form').attr('action', url)
    $('#bookmark-update-form-wrapper form .bookmark_link input').val(link);
    $('#bookmark-update-form-wrapper form .bookmark_name input').val(name);
    $('#bookmark-update-form-wrapper form .bookmark_bookmark_folder select').val(targetFolderId);
    Rails.fire($('#bookmark-update-form-wrapper form')[0], 'submit');
  }
});