$(document).on("turbolinks:before-cache", function() {
    var table = $('#creators-table').dataTable( { retrieve: true });
    if(table.length > 0) {
      table.fnDestroy();
    }
  });
  
  $(document).on('ready page:load turbolinks:load', function() {
   
    var isSearchable = $('#creators-table tbody tr').length > 4;
  
    $('#creators-table').dataTable( {
      destroy: true,
      "bFilter": isSearchable, // determine whether to show search boc
      "aoColumnDefs": [
        { "bSortable": true, "bSearchable": false, "aTargets": [ 0 ] },
        { "bSortable": true, "bSearchable": isSearchable, "aTargets": [ 2 ] },
        { "bSortable": false, "bSearchable": isSearchable, "aTargets": [ 3 ] },
        // create a special hidden column for the plain text value to search on
        // otherwise the search logic ends up matching on the url of the link
        { "bSortable": false, "bSearchable": isSearchable, "bVisible": false, "aTargets": [ 1 ] }
      ],
      "aaSorting": [[0, 'asc']], // sort by first column to start
      "bPaginate": false, // no pagination controls
      "bInfo": false, // do not display row counts (e.g. 1 of 2 records found)
      "oLanguage":{
        "sSearch": "", // No label for search box. We add placeholder below
        "sZeroRecords": "No matching creators"
      },
      "dom": "<'row'<'col-sm-6'f><'col-sm-6'l>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>"
    });
  
    if(isSearchable) {
      $('#creators-table_wrapper .dataTables_filter input').addClass("form-control");
      $('#creators-table_wrapper .dataTables_filter input').attr("placeholder", "Filter");
      $('#creators-table_wrapper .dataTables_filter input').focus();
    }
});