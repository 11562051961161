import Rails from '@rails/ujs';
import Sortable from 'sortablejs';

$(document).on('ready page:load turbolinks:load', function() {

  new MediaAssetAction().bindTagInputs('#new_media_request .tagsinput', '#new_media_request .tagsinput-overlay');

  $('.tackboard-posts.sortable').each(function(){
    Sortable.create($(this)[0], {
      filter: '.unsortable',
      onStart: function () {
        // Save order before sort
        this._currentOrder = this.toArray();
      },
      onUpdate: function (evt) {
        evt.preventDefault();
        // Disabling sorting
        this.option('disabled', true);
        this.el.classList.add('updating');

        setTimeout(function () {
          var form = $(this.el).parent().find('.reorder-form');
          form.find('#tackboard_reorder_from').val(evt.oldIndex);
          form.find('#tackboard_reorder_to').val(evt.newIndex);
          Rails.fire(form[0], 'submit');

          this.option('disabled', false);
          this.el.classList.remove('updating');
        }.bind(this), 200);
      }
    });
  });
});
