$(document).on('ready page:load turbolinks:load bookmarks:reload', function() {
  var folderContainer = $('.bookmarks-manager .bookmark-folders').parent()  
  var bookmarksContainer = $('.bookmarks-manager .bookmarks').parent()  
  if($('.bookmarks-manager .bookmark-folders li').length <= 1) {
    folderContainer.addClass('hidden');
    bookmarksContainer.addClass('col-sm-12');
    bookmarksContainer.removeClass('col-sm-9')
    $('#bookmark-add-new-folder').removeClass('hidden');
  }
  else {
    folderContainer.removeClass('hidden');
    bookmarksContainer.addClass('col-sm-9');
    bookmarksContainer.removeClass('col-sm-12')
    $('#bookmark-add-new-folder').addClass('hidden');
  }
});
