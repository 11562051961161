$(document).on('ready page:load turbolinks:load', function() {
	$("#select-all-checkbox").change(function () {
      var is_checked = this.checked;
		  $('.selectable-checkbox').each(function() {
        this.checked = is_checked;
      });
	});

	$(".selectable-checkbox").change(function(){
		if($(".selectable-checkbox").length == $(".selectable-checkbox:checked").length) {
			$("#select-all-checkbox").each(function() { this.checked = true; });
		} else {
      $("#select-all-checkbox").each(function() { this.checked = false; });
		}
	});

	$("#user-show-api-key").change(function(){
		var is_checked = this.checked;
		if(is_checked) {
			$("#user_api_key").val($("#user_api_key").data('api-key'));
		} else {
			$("#user_api_key").val('************');
		}
	});
});
