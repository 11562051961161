import predefinedRangesPlugin from './flatpickr_predefined_ranges'
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin'
import { DateTime } from 'luxon';
import Chartkick from 'chartkick'
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chartkick.addAdapter(Chart);

const totalizer = {
  id: 'totalizer',

  beforeUpdate: chart => {
    let totals = {}
    let utmost = 0

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      if (chart.isDatasetVisible(datasetIndex)) {
        utmost = datasetIndex
        dataset.data.forEach((value, index) => {
          totals[index] = (totals[index] || 0) + value
        })
      }
    })

    chart.$totalizer = {
      totals: totals,
      utmost: utmost
    }
  }
}
Chart.plugins.register(totalizer);
Chart.helpers.merge(Chart.defaults.global.plugins.datalabels, {
  formatter: (value, ctx) => {

    var stacked = ctx.chart.config.options.scales.yAxes[0].stacked;
    
    if(stacked) {
      const total = ctx.chart.$totalizer.totals[ctx.dataIndex]
      return total.toString();
    }

    return ctx.dataset.data[ctx.dataIndex];
  },
  align: 'end',
  anchor: 'end',
  display: function(ctx) {
    var stacked = ctx.chart.config.options.scales.yAxes[0].stacked;

    if(stacked) {
      return ctx.datasetIndex === ctx.chart.$totalizer.utmost && ctx.chart.$totalizer.totals[ctx.dataIndex] > 0
    }

    return ctx.dataset.data[ctx.dataIndex] > 0;
  }
});

$(document).on("turbolinks:before-cache", function() {
  $('.social-profiles__list').select2('destroy');
  $('.new_report_filter .flatpickr').each(function(i, el) { el._flatpickr.destroy(); } );
});

$(document).on('ready page:load turbolinks:load', function() {
  
  $('.social-profiles__list').select2({
    theme: "bootstrap",
    sorter: function(data) {
      return data.sort(function (a, b) {
          if(a.id == '-1') {
            return -1;
          }
          if(b.id == '-1') {
            return 1;
          }

          a = a.text.toLowerCase();
          b = b.text.toLowerCase();
          if (a > b) {
            return 1;
          } else if (a < b) {
            return -1;
          }
          return 0;
      });
    },
    templateSelection: function(data) {
      if($(data.element).data('profile-picture') == undefined) {
        return data.text;
      }
      var img = $('<img>').attr('src',$(data.element).data('profile-picture')).addClass('social-profiles__img');
      return $('<div style="display:inline">').append(img).append(' ' + data.text);
    },
    templateResult: function(data) {
      if($(data.element).data('profile-picture') == undefined) {
        return $('<div style="margin-left: 30px">').append(data.text);
      }
      var img = $('<img>').attr('src',$(data.element).data('profile-picture')).addClass('social-profiles__img');
      return $('<div style="display:inline">').append(img).append(' ' + data.text);
    }
  }); 
  
  $('.social-profiles__list').on('change.select2', function (e) {
    var select2data = $('.social-profiles__list').select2('data')
    e.currentTarget.dispatchEvent(new CustomEvent('report_filter:changed', { bubbles: true, detail: { social_profile: select2data[0] } }));
  });

  var dt = DateTime.local();


  $('.new_report_filter .flatpickr').flatpickr({
    wrap: true,
    altInput: true,
    altFormat: "M j, Y",
    dateFormat: "Y-m-d",
    minDate: dt.minus({months: 13}).startOf('day').toJSDate(),
    maxDate: dt.endOf('day').toJSDate(),
    defaultDate: [dt.minus({days: 91}).startOf('day').toJSDate(), dt.minus({days: 1}).endOf('day').toJSDate()],
    mode: 'range',
    onChange: function(selectedDates, dateStr, instance) {
      instance.element.dispatchEvent(new CustomEvent('report_filter:changed', { bubbles: true, detail: { dates: selectedDates } }));
    },
    "plugins": [new rangePlugin({ input: "#report_filter_end_at" }), new predefinedRangesPlugin(
      [
        { label: 'Month to date', start: dt.startOf('month').toJSDate(), end: dt.endOf('day').toJSDate(), isDefaultSelected: false },
        { label: 'Last month', start: dt.minus({months: 1}).startOf('month').toJSDate(), end: dt.minus({months: 1}).endOf('month').toJSDate(), isDefaultSelected: false },
        { label: 'Last 30 days', start: dt.minus({days: 31}).startOf('day').toJSDate(), end: dt.minus({days: 1}).endOf('day').toJSDate(), isDefaultSelected: false },
        { label: 'Last 90 days', start: dt.minus({days: 91}).startOf('day').toJSDate(), end: dt.minus({days: 1}).endOf('day').toJSDate(), isDefaultSelected: true },
        { label: 'Year to date', start: dt.startOf('year').toJSDate(), end: dt.endOf('day').toJSDate(), isDefaultSelected: false },
      ]
      )]
  });

});

$(document).on('report_filter:changed', function (e) {
  var zone = $('#current-user-time-zone').data('time-zone');
  var start_at = null;
  var end_at = null;
  var social_profile = null;

  if (e.detail.dates != null) {
    start_at = DateTime.fromISO(e.detail.dates[0].toISOString()).setZone(zone, { keepLocalTime: true }).toISO();
    end_at = DateTime.fromISO(e.detail.dates[1].toISOString()).endOf('day').setZone(zone, { keepLocalTime: true }).toISO();
  }

  if (e.detail.social_profile != null) {
    social_profile = e.detail.social_profile.id;
  }

  Chartkick.eachChart( function(chart) {
    var url = chart.dataSource.split('?')[0];
    var query = chart.dataSource.split('?')[1];
    var params = new URLSearchParams(query)
     
    if (start_at != null) {
      params.set('start_at', start_at)
    }

    if (end_at != null) {
      params.set('end_at', end_at)
    }

    if (social_profile != null) {
      params.set('social_profile', social_profile);
    }
    
    chart.updateData(url + '?' + params.toString());
  });

  $('.download-link').each(function(index, el) {
    var url = el.href.split('?')[0];
    var query = el.href.split('?')[1];
    var params = new URLSearchParams(query)
     
    if (start_at != null) {
      params.set('start_at', start_at)
    }

    if (end_at != null) {
      params.set('end_at', end_at)
    }

    if (social_profile != null) {
      params.set('social_profile', social_profile);
    }
    
    el.href = url + '?' + params.toString();
  });

});
